.custom-button-container {
  box-shadow: var(--external-box-shadow);
  padding: 5px;
  button:disabled,
  button[disabled] {
    cursor: not-allowed;
    // color: #ccc;
    // border-color: #ccc;
  }

  @mixin btn-border-drawing(
    $color: #fff,
    $hover: black,
    $width: 2px,
    $vertical: top,
    $horizontal: left,
    $duration: 0.25s
  ) {
    box-shadow: inset 0 0 0 $width $color;
    color: $color;
    transition: color $duration $duration/3;
    position: relative;

    &::before,
    &::after {
      border: 0 solid transparent;
      box-sizing: border-box;
      content: "";
      pointer-events: none;
      position: absolute;
      width: 0;
      height: 0;

      #{$vertical}: 0;
      #{$horizontal}: 0;
    }

    &::before {
      $h-side: if($horizontal== "left", "right", "left");

      border-#{$vertical}-width: $width;
      border-#{$h-side}-width: $width;
    }

    &::after {
      $v-side: if($vertical== "top", "bottom", "top");

      border-#{$v-side}-width: $width;
      border-#{$horizontal}-width: $width;
    }

    &:hover {
      color: $hover;

      &::before,
      &::after {
        border-color: $hover;
        transition: border-color 0s, width $duration, height $duration;
        width: 100%;
        height: 100%;
      }
      &::before {
        transition-delay: 0s, 0s, $duration;
      }
      &::after {
        transition-delay: 0s, $duration, 0s;
      }
    }
  }

  // .draw-border {
  //   @include btn-border-drawing(
  //     var(--main-color),
  //     var(--main-color),
  //     2px,
  //     bottom,
  //     right
  //   );
  // }
  //=== Button styling, semi-ignore
  .btn-custom {
    background: none;
    border: none;
    cursor: pointer;
    line-height: 1.5;
    padding: 15px;
    font-weight: bold;
    color: var(--main-color);
    // border-radius: 25px;

    // &:focus {
    // outline: 2px dotted #55d7dc;
    // }
  }
}
