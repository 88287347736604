:root {
  --main-color: #002c85;
  --light-color: #ecf0f3;
  --external-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  // --external-box-shadow: 1px 1px 1px #d1d9e6, -3px -3px 3px #f9f9f9;
  --internal-box-shadow: inset 2px 2px 4px #d1d9e6, inset -2px -2px 4px #f9f9f9;
  --main-font: "Raleway";
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

html {
  scroll-behavior: smooth;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

a {
  text-decoration: none !important;
}

.custom-link-color {
  color: var(--main-color);
}

body {
  line-height: 1;
  overflow-x: hidden;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: var(--main-font);
  background-color: var(--light-color);
  transition: 3s;
}

*,
::after,
::before {
  box-sizing: border-box;
}

*:focus {
  outline: none !important;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}
div.MuiPaper-root {
  background-color: var(--light-color) !important;
  border-radius: 0px;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  box-shadow: none;
  background-color: #010a1d;
  border-radius: 0px;
}

::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: transparent;
  border-radius: 0px;
  background-color: var(--light-color);
}

.section-title {
  font-size: 36px;
  font-weight: bold;
  color: var(--main-color);
}

input select {
  outline: none;
}

#root {
  background-color: var(--light-color);
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  min-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
}
