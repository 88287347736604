.suspense-container {
  width: 100%;
  max-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light-color);
  margin-top: 45vh;
  flex-direction: column;
  .suspense-text {
    color: var(--main-color);
  }

  @keyframes ldio-i9t3xk8hcnb {
    0% {
      transform: translate(9.84px, 65.6px) scale(0);
    }
    25% {
      transform: translate(9.84px, 65.6px) scale(0);
    }
    50% {
      transform: translate(9.84px, 65.6px) scale(1);
    }
    75% {
      transform: translate(65.6px, 65.6px) scale(1);
    }
    100% {
      transform: translate(121.36px, 65.6px) scale(1);
    }
  }
  @keyframes ldio-i9t3xk8hcnb-r {
    0% {
      transform: translate(121.36px, 65.6px) scale(1);
    }
    100% {
      transform: translate(121.36px, 65.6px) scale(0);
    }
  }
  @keyframes ldio-i9t3xk8hcnb-c {
    0% {
      background: #002c85;
    }
    25% {
      background: #002c85;
    }
    50% {
      background: #002c85;
    }
    75% {
      background: #002c85;
    }
    100% {
      background: #002c85;
    }
  }
  .ldio-i9t3xk8hcnb div {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    transform: translate(65.6px, 65.6px) scale(1);
    background: #002c85;
    animation: ldio-i9t3xk8hcnb 2.272727272727273s infinite
      cubic-bezier(0, 0.5, 0.5, 1);
  }
  .ldio-i9t3xk8hcnb div:nth-child(1) {
    background: #002c85;
    transform: translate(121.36px, 65.6px) scale(1);
    animation: ldio-i9t3xk8hcnb-r 0.5681818181818182s infinite
        cubic-bezier(0, 0.5, 0.5, 1),
      ldio-i9t3xk8hcnb-c 2.272727272727273s infinite step-start;
  }
  .ldio-i9t3xk8hcnb div:nth-child(2) {
    animation-delay: -0.5681818181818182s;
    background: #002c85;
  }
  .ldio-i9t3xk8hcnb div:nth-child(3) {
    animation-delay: -1.1363636363636365s;
    background: #002c85;
  }
  .ldio-i9t3xk8hcnb div:nth-child(4) {
    animation-delay: -1.7045454545454546s;
    background: #002c85;
  }
  .ldio-i9t3xk8hcnb div:nth-child(5) {
    animation-delay: -2.272727272727273s;
    background: #002c85;
  }
  .loadingio-spinner-ellipsis-nwpk69546c9 {
    width: 164px;
    height: 164px;
    display: inline-block;
    overflow: hidden;
    background: #ecf0f3;
  }
  .ldio-i9t3xk8hcnb {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-i9t3xk8hcnb div {
    box-sizing: content-box;
  }
}
